.bridge-bgd {
  background: url(/images/nasa.png);
  background-repeat: repeat;
  background-size: auto;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
}
.left-sidebar {
  position: fixed;
  width: 50%;
}
@media only screen and (max-width: 768px) {
  .left-sidebar {
    position: relative;
    width: 100%;
  }
  .bridge-bgd {
    width: 100%;
    height: 60vh;
  }
}
input {
  margin-bottom: 10px;
}

.form-control.age-margin {
  margin-left: -16px;
}

.cert-white-logo {
  height: 30px;
  width: auto;
  margin-top: 20px;
  margin-left: 10px;
}

.topbar-social-flex {
  display: flex;
  justify-content: space-between;
}

.social-sm-flex {
  display: flex;
  list-style-type: none;
  position: relative;
  left: 100%;
}

.social-img {
  width: 20px;
  height: 20px;
  margin: 7px;
  margin-top: 20px;
}

.age-flex {
  display: flex;
}

.certi-link {
  width: 110px;
  height: 38px;
  background-color: #004f98;
  color: #ffffff !important;
  text-align: center;
  padding-top: 6px;
  border-radius: 20px;
}

.terms-style {
  color: #004f98;
  font-weight: bold;
}

.certidapp-btn {
  margin-top: 20%;
  display: flex;
  justify-content: flex-end;
}

h1 {
  color: #ffffff;
  text-align: center;
}

h5 {
  color: #ffffff;
  text-align: center;
}

.proceed-box-flex {
  display: flex;
  justify-content: center;
}

.inner-box {
  display: block;
}

button {
  border-width: 0px;
}

a {
  text-decoration: none;
  text-underline-position: unset;
}

.bnch-link {
  color: #80808099;
  font-weight: 500;
}

.bnch-link:hover {
  color: #80808099 !important;
}

a:hover {
  color: #000000 !important;
}

.prcd-btn:hover {
  color: #ffffff !important;
}

.prcd-btn {
  background-color: #b95866;
  color: #ffffff;
  text-align: center;
  border-color: #a7a7a7;
  border-radius: 8px;
  margin-bottom: 44px;
  padding-left: 17px;
  padding-right: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bunch-btn {
  background-color: #b95866;
  color: #ffffff;
  text-align: center;
  margin: 10px;
  width: 112px;
  height: 32px;
  border-radius: 6px;
}

.frst-link {
  margin-bottom: 15px;
  margin-top: 10px;
  margin-left: 5px;
}

.wrapper-container {
  padding-top: 12%;
}

.mrg-home {
  margin-bottom: 15px;
}

.bunch-btn-flex {
  display: flex;
  justify-content: center;
}

.modal-content {
  top: 200px;
}

.bunch-hd-txt {
  color: #80808099;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
}

.bunch-dropdown-center {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.gen-bunch-center {
  display: flex;
  justify-content: center;
  border: 2px solid #80808099;
  width: 90%;
  margin-left: 30px;
  height: 38px;
  border-radius: 10px;
  padding-top: 6px;
}

.bunch-border.custom-select {
  border: 2px solid #80808099;
  width: 395px;
  margin-left: 13px;
  height: 38px;
  border-radius: 10px;
  padding-top: 6px;
  text-align: center;
}

option {
  width: 389px;
  text-align: center;
}

.main-genBunch-btn {
  border: 1px solid #80808099;
  width: 90%;
  margin-left: 30px;
  height: 38px;
  border-radius: 10px;
  padding-top: 1px;
  background-color: #b95866;
  color: #ffffff;
}

.radio-btn {
  margin-top: 6px;
  margin-right: 6px;
  margin-left: 6px;
}

.fill-head {
  font-size: 19px;
  margin-left: 0px;
  margin-bottom: 36px;
}

.checkbox-btn {
  margin-right: 8px;
}

.fa.fa-navicon {
  font-size: 36px;
}

.navicon-flex {
  display: flex;
  justify-content: flex-end;
  margin-right: 0;
  margin-top: 0;
}
.nav .num-col {
  font-size: 15px;
  color: #80808099;
  font-weight: bold;
  margin-left: 0px;
}

.thin-border1 {
  border: 1px solid #004f98;
  width: 20%;
  margin-bottom: 50px;
}

.rightsideform {
  width: 76%;
}

.tabs {
}
.nav-tabs {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  margin-left: 0;
  list-style: none;
}

.nav-tabs::before,
.nav-pills::before,
.nav-tabs::after,
.nav-pills::after {
  display: table;
  line-height: 0;
  content: '';
}
.nav-tabs > li {
  margin-bottom: -1px;
  float: left;
  line-height: 20px;
  list-style: none;
}
.nav-tabs > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 20px;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: 2px;
  border: 1px solid transparent;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
  display: block;
  color: #040404 !important;
  text-decoration: none;
  font-weight: 600;
}
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: rgb(221, 221, 221);
  border-bottom-color: transparent;
  color: #004f98 !important;
}

.tab:not(:target) {
  display: none;
}

.tab:last-child {
  display: block;
}

.tab:target ~ .tab:last-child {
  display: none;
}

.formbg {
  text-align: left;
}
.formbg .radio-btn {
  margin-top: 7px;
  margin-right: 6px;
  margin-left: 10px;
}
Label {
  font-weight: 700;
}

.new-quesedit h6 {
  width: 90%;
  font-weight: 600;
}
.new-quesedit .editbtn {
  position: absolute;
  right: 5px;
  top: 6px;
}
.survey,
.left-sidebar,
.right-sidebar {
  overflow: hidden;
}
table a {
  color: #004f98;
}
.no-ans-sel {
  max-width: 45px;
  margin-left: 15px;
}

.progress {
  width: 150px;
  height: 150px;
  line-height: 150px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}
.progress:after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 12px solid #fff;
  position: absolute;
  top: 0;
  left: 0;
}
.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progress .progress-left {
  left: 0;
}
.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 12px;
  border-style: solid;
  position: absolute;
  top: 0;
}
.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  -webkit-transform-origin: center left;
  transform-origin: center left;
}
.progress .progress-right {
  right: 0;
}
.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  -webkit-transform-origin: center right;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}
.progress .progress-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: #44484b;
  font-size: 24px;
  color: #fff;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
}
.progress.blue .progress-bar {
  border-color: #049dff;
}
.progress.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}
.overall-progess-est h5 {
  color: #004f98;
  font-size: 13px;
  font-weight: 600;
}
.overall-progess-est h4 {
  font-size: 13px;
  font-weight: 600;
}
.summary-acc .summary-qs {
  width: 90%;
}
.summary-acc .summary-qs .radio-btn {
  position: absolute;
  right: 20px;
  top: 26px;
}
.navicon-flex {
  display: flex;
  justify-content: flex-end;
  margin-right: 0;
  margin-top: 0;
  position: absolute;
  right: 50px;
  top: 10px;
}
