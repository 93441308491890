body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1 {
  color: inherit !important;
  text-align: left;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.footer,
.tiny-footer {
  /* width: 100vw; */
  background-color: black;
}
.social2 a {
  text-decoration: none;
  color: white;
}
.tiny-footer a {
  text-decoration: none;
  color: white;
}

.tiny-footer,
.social2 a:hover {
  color: rgb(224, 227, 230) !important ;
}

.border-thin {
  border-width: 60px !important;
}
.text-area {
  resize: none;
  overflow: hidden;
  min-height: calc(1.5em + 0.75rem + 2px);
}
pre {
  display: flex;
  white-space: pre-wrap;
  word-break: break-word;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: center;
}
.loader {
  position: relative;
  top: 50%;
  width: 80px;
  height: 80px;
}
.error {
  background: url(/images/404.jpg);
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background-position: center;
}

.wallet {
  width: 100px;
  height: 100px;
  margin-top: auto;
}
