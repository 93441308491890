/*----------------
margin space
-----------------*/

.mb0 {
  margin-bottom: 0px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb40 {
  margin-bottom: 40px;
}
.mb60 {
  margin-bottom: 60px;
}
.mb80 {
  margin-bottom: 80px;
}
.mb100 {
  margin-bottom: 100px;
}
.mt0 {
  margin-top: 0px;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.mt60 {
  margin-top: 60px;
}
.mt80 {
  margin-top: 80px;
}
.mt100 {
  margin-top: 100px;
}
.mr0 {
  margin-right: 0px;
}
.mr10 {
  margin-right: 10px;
}
.mr20 {
  margin-right: 10px;
}
.ml0 {
  margin-left: 0px;
}
.ml10 {
  margin-left: 10px;
}
.ml20 {
  margin-left: 20px;
}

/*----------------
less margin space from top
-----------------*/
.mt-20 {
  margin-top: -20px;
}
.mt-40 {
  margin-top: -40px;
}
.mt-60 {
  margin-top: -60px;
}
.mt-80 {
  margin-top: -80px;
}

/*---------------
Padding space
-----------------*/
.nopadding {
  padding: 0px;
}
.nopr {
  padding-right: 0px;
}
.nopl {
  padding-left: 0px;
}
.pinside10 {
  padding: 10px;
}
.pinside20 {
  padding: 20px;
}
.pinside30 {
  padding: 30px;
}
.pinside40 {
  /* padding: 40px; */
}
.pinside50 {
  padding: 50px;
}
.pinside60 {
  padding: 60px;
}
.pinside80 {
  padding: 80px;
}
.pinside100 {
  padding: 100px;
}
.pdt10 {
  padding-top: 10px;
}
.pdt20 {
  padding-top: 20px;
}
.pdt30 {
  padding-top: 30px;
}
.pdt40 {
  padding-top: 40px;
}
.pdt60 {
  padding-top: 60px;
}
.pdt80 {
  padding-top: 80px;
}
.pdb10 {
  padding-bottom: 10px;
}
.pdb20 {
  padding-bottom: 20px;
}
.pdb30 {
  padding-bottom: 30px;
}
.pdb40 {
  padding-bottom: 40px;
}
.pdb60 {
  padding-bottom: 60px;
}
.pdb80 {
  padding-bottom: 80px;
}
.pdl10 {
  padding-left: 10px;
}
.pdl20 {
  padding-left: 20px;
}
.pdl30 {
  padding-left: 30px;
}
.pdl40 {
  padding-left: 40px;
}
.pdl60 {
  padding-left: 60px;
}
.pdl70 {
  padding-left: 70px;
}
.pdl80 {
  padding-left: 80px;
}
.pdl120 {
  padding-left: 120px;
}
.pdr10 {
  padding-right: 10px;
}
.pdr20 {
  padding-right: 20px;
}
.pdr30 {
  padding-right: 30px;
}
.pdr40 {
  padding-right: 40px;
}
.pdr60 {
  padding-right: 60px;
}
.pdr80 {
  padding-right: 80px;
}
* {
  padding: 0;
  margin: 0;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.63);
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0;
}
blockquote {
  color: #666;
  margin: 0;
  padding-left: 3em;
  border-left: 0.5em #eee solid;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75) !important;
}
.btn:hover {
  color: #f7ff00 !important;
  text-decoration: none;
}
.prev-link-style {
  text-decoration: none;
}
a.next-certi-link:hover {
  color: #f7ff00 !important;
  text-decoration: none;
}
a.prev-link-style:hover {
  color: #f7f3f3 !important;
  text-decoration: none;
  background: #1c1c1c;
}

.social-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

.social-menu li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 6px;
}

.social-menu li a i {
  width: 31px;
  height: 30px;
  margin-top: 15px;
  background-color: #fff0;
  text-align: center;
  line-height: 30px;
  border-radius: 3px;
  color: #005099;
  font-size: 23px;
  box-shadow: 0 0 0 0 transparent;
  transition: 0.3s;
  border: 1px solid #ffffff61;
}

.social-menu li a:hover i {
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}
