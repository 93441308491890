.homebg {
  background: #f5f5f5;
}
.banner {
  background: url(/images/bannerbg.png);
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
}
.banner .combtn {
  margin-bottom: 15px;
  margin-right: 10px;
}

.banner .wrapper-container {
  padding-top: 19%;
}

.mrg-home {
  margin-bottom: 15px;
  color: #fff !important;
}

.combtn:hover {
  color: #ffffff !important;
}

.combtn {
  background-color: #004f98;
  color: #ffffff !important;
  text-align: center;
  border-color: #a7a7a7;
  border-radius: 16px;
  padding-left: 17px;
  padding-right: 17px;
  border: none;
  border: 1px solid #eeeeee4d;
}
.white-logo {
  width: auto;
  height: 40px;
  margin: 0px;
}
.counter-box {
  display: block;
  padding: 40px 20px 37px;
  text-align: center;
  border: 1px solid #dddddd;
  background-color: #fff;
}

.counter-box p {
  margin: 5px 0 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
}

.counter-box i {
  font-size: 60px;
  margin: 0 0 15px;
  color: #d2d2d2;
}

.counter {
  display: block;
  font-size: 32px;
  font-weight: 700;
  color: #666;
  line-height: 28px;
}

.counter-box.colored {
  background: #3acf87;
}

.counter-box.colored p,
.counter-box.colored i,
.counter-box.colored .counter {
  color: #fff;
}
.graybox {
  background: #f5f5f5;
}
.border-right {
  border-right: 1px solid #eee;
}
.main-section {
  padding: 20px;
  text-align: left;
}
.main-section .extrasmall {
  font-size: 10px;
}
.card-part {
  border-radius: 5px;
  margin: 0px;
  border: 1px solid #dddddd;
  background-color: #fff;
  padding-bottom: 20px;
}
.card-title h1 {
  margin: 0px;
  color: #000;
  font-size: 23px;
  padding: 15px 0px 8px 0px;
  text-align: left;
}
.card-description p {
  min-height: 10px;
  overflow: hidden;
  color: #848484;
  margin: 0px;
}
.card-cart a {
  border-radius: 0px;
  font-size: 11px;
}
.card-cart {
  padding-top: 10px;
}
.header-title h3 {
  font-size: 22px;
  font-weight: 600;
}
.bg-light.scrolled {
  background-color: #9e9e9e !important;
  transition: 500ms ease;
}
.survey-nav .fixed-top {
  background: #000 !important ;
}
